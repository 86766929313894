import './VerticalProject.css';

const VerticalProject = ({
  title,
  description,
  image,
  logo
}: {
  title: string;
  description: string;
  image: any;
  logo: any;
}) => {
  return (
    <div className="vp-project">
      <div className="vp-project-image-container">
        <div className="vp-relative">
          <img className="vp-logo" src={logo} />
          <img className="vp-image" src={image} />
        </div>
      </div>
      <div className="vp-project-description">
        <div className="vp-project-title">{title}</div>
        <p>{description}</p>
      </div>
    </div>
  );
};

export default VerticalProject;
