import './App.css';
import Intro from '../Intro';
import Description from '../Description';
import Projects from '../Projects';
import Footer from '../Footer';

function App() {
  return (
    <div className="App">
      <Intro />
      <Description />
      <Projects />
      <Footer />
    </div>
  );
}

export default App;
