import './Pill.css';

const Pill = ({ title, alternate }: { title: string; alternate: string }) => {
  return (
    <div className="pill">
      <span className="main">{title}</span>
      <span className="alternate">{alternate}</span>
    </div>
  );
};

export default Pill;
