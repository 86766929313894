import './Intro.css';
import bigFish from '../../resources/intro/bigfish.webp';
import bolzano from '../../resources/intro/bolzano.webp';
import florence from '../../resources/intro/florence.webp';
import itba from '../../resources/intro/itba.webp';
import hockey from '../../resources/intro/hockey.webp';
import photography from '../../resources/intro/photography.webp';
import roc from '../../resources/intro/roc.webp';

const Intro = () => {
  return (
    <div className="intro">
      <div className="gallery">
        <img className="images" src={bolzano} />
        <img className="images" src={florence} />
        <img className="images" src={bigFish} />
        <img className="images" src={itba} />
        <img className="images" src={hockey} />
        <img className="images" src={photography} />
        <img className="images" src={roc} />
      </div>
      <div className="introRight">
        <span className="introPosition">SOFTWARE ENGINEER</span>
        <h1 className="introName">CONSTANZA DE RIENZO</h1>
      </div>
    </div>
  );
};

export default Intro;
