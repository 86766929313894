import './Footer.css';
import { FaInstagram, FaLinkedinIn } from 'react-icons/fa6';
import { RiFileUserLine } from 'react-icons/ri';
import { FiCamera } from 'react-icons/fi';

import cv from '../../resources/footer/Constanza_De_Rienzo_CV.pdf';

const Footer = () => {
  return (
    <div className="footer">
      <span className="fun-fact">
        Fun fact: I designed and implemented this website from scratch in React!
      </span>
      <div className="footer-low">
        <div className="footer-icons">
          <span>
            <a href="https://www.instagram.com/coniderienzo" target="_blank">
              <FaInstagram />
            </a>
          </span>
          <span>
            <a href="https://www.linkedin.com/in/constanza-de-rienzo/" target="_blank">
              <FaLinkedinIn />
            </a>
          </span>
          <span>
            <a href={cv} target="_blank">
              <RiFileUserLine />
            </a>
          </span>
          <span>
            <a href="https://www.instagram.com/ph.cdr" target="_blank">
              <FiCamera />
            </a>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
